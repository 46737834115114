import Cookie from "js-cookie";
import { Component, Vue } from "vue-property-decorator";
import Template from "./unauthorized.template.vue";

@Component({
  mixins: [Template]
})
export default class UnauthorizedComponent extends Vue {
  public created() {
    Cookie.set("referrer_app", process.env.VUE_APP_DISPENSARY_FRONT, {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
    window.location.href = process.env.VUE_APP_CORE_FRONTEND;
  }
}
